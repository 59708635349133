import React, { Component, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useTable } from 'react-table';
import { BadDataGraph } from './BadDataGraph';
//// import BadDataTable from "./BadDataTable";

export default class MeterTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      meter_data: props.meter_data, //This is the list of devices for the selected report
      site_tz: props.report_time_zone,
      report_bill_id: props.report_bill_id,
      //Holder for the device table selection
      default_param_id: undefined,
      device_id: undefined,
      device_name: '',
      collector_name: '',
    };
  }

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    if (prevProps.meter_data !== this.props.meter_data) {
      this.setState({
        meter_data: this.props.meter_data,
      });
    }
    if (prevProps.report_bill_id !== this.props.report_bill_id) {
      this.setState({
        report_bill_id: this.props.report_bill_id,
      });
    }
    if (prevProps.report_time_zone !== this.props.report_time_zone) {
      this.setState({
        site_tz: this.props.report_time_zone,
      });
    }
  }

  render() {
    const { meter_data, site_tz, report_bill_id } = this.state;
    
    const columns = [
      {
        Header: 'pid',
        accessor: 'default_param_id',
        key: 'default_param_id',
      },
      {
        Header: 'DID',
        accessor: 'device_id',
        key: 'device_id',
      },
      {
        Header: 'Device Name',
        accessor: 'device_name',
        key: 'device_name',
      },
      {
        Header: 'Collector Name',
        accessor: 'collector_name',
        key: 'collector_name',
      },
    ];

    /**
     * This produces the main data table of data from the DRD table
     *
     * @param columns
     * @param data
     * @returns {Element}
     * @constructor
     */
    function TableRender({ columns, data }) {
      const [device_id, _set_device_id] = useState('');

      // const [default_param_id, set_default_param_id] = useState("");

      /**
       * Handles when the user selected a specific device in the table
       * @param row
       */
      const handle_bad_data_expand = (row) => {
        // {
        //     "default_param_id": 25,
        //     "device_id": 5286,
        //     "device_name": "Unit 532 - Cold Water",
        //     "collector_name": "NextCentury Water Meters"
        // }
        //.values.device_id
        console.log(row);
        // set_device_id(row.values.device_id);
        // set_default_param_id(22);
      };

      // const detect_table_pan =()=>{
      //     set_device_id("")
      // }

      //Hide the default_param_id column
      const initialState = { hiddenColumns: ['default_param_id'] };

      //Initialize the table
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = useTable({
        columns,
        data,
        state: initialState,
      });

      //Sort the rows by device_id
      rows.sort((a, b) => a.values.device_id - b.values.device_id);

      return (
        <>
          {meter_data.length > 0 && (
            <div>
              <Table //Bootstrap table settings
                striped
                bordered
                hover
                responsive
                size='sm'
                // variant="dark"
                {...getTableProps()}
              >
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr key={'header'} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th key={column.key} {...column.getHeaderProps()}>
                          {column.render('Header')}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <React.Fragment key={i}>
                        <tr
                          key={row.values.device_id}
                          {...row.getRowProps()}
                          onClick={(_e) => handle_bad_data_expand(row)}
                          className={
                            device_id === row.values.device_id
                              ? 'highlight_sel_row'
                              : ''
                          }
                        >
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </Table>
              <BadDataGraph site_tz={site_tz} report_bill_id={report_bill_id} />
            </div>
          )}
        </>
      );
    }

    return (
      <div className='mt-3'>
        <TableRender
          columns={columns}
          data={meter_data}
          site_tz={site_tz}
          report_bill_id={report_bill_id}
          // submit_rbid={submit_rbid}
        />
      </div>
    );
  }
}
