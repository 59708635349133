import React, { Component } from "react";
import Navigation from "../Navbar";
import SiteSelect from "../Global/Site_select2";
import GroupSelect from "../Global/GroupSelect";
import { Fetch } from "../../helpers/Fetch";
import TenantInfoTable from "./TenantInfoTable";
// import ExtraLineTable from "../Extra_Line_Items/ExtraLineTable";

export default class Tenant_Info extends Component {
  constructor(props) {
    super(props);

    // console.log("constructor");
    this.state = {
      group_data: [],
      table_data: [],
      report_group_id: sessionStorage.getItem("prev_group_id"),
      show_report_runs_error_modal: false,
      site_id: sessionStorage.getItem("prev_site_id"),
      tenant_info_data: [],
      offset: 0,
      // site_selected: false,
    };

    //Get any previous selection
    this.site_id = sessionStorage.getItem("prev_site_id");
    this.site_tz = sessionStorage.getItem("prev_site_tz");

    this.group_selected = false;

    //If there was a previous site selected
    if (this.site_id) {
      this.site_selected = true;
      this.get_all_groups(this.site_id);
      this.getTenantsInfoData()
    } else {
      this.site_selected = false;
    }
  }

  get_all_groups = (site_id) => {
    Fetch(`Report_Runs.php`, {
      action: "fill_groups_dropdown",
      site_id: site_id,
    })
      .then((data) => {
        // console.log(
        //   "Number of groups returned",
        //   data.data.length,
        //   data.data[0].report_group_id,
        //   data
        // );
        if (data.data.length === 1) {
          this.setState({
            group_data: data.data,
            report_group_id: data.data[0].report_group_id,
            report_group_name: data.data[0].report_group_name,
          });
          this.group_selected = true;

        } else {
          this.group_selected = false;
          this.setState({
            group_data: data.data,
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getTenantsInfoData = (e, val) => {
    if(val){
      sessionStorage.setItem("prev_group_id", val && val.value);
      sessionStorage.setItem("prev_group_name",val && val.label);
      sessionStorage.setItem("rgid", val.value);
    }

    const { offset, site_id } = this.state;
    Fetch("tenant_info_api.php", {
      action: "fill_tenant_info_table",
      site_id: site_id,
      viewConsump: "all",
      offset: offset,
    }).then((data) => {
      if (data.status === "ok") {
        this.setState({
          tenant_info_data: data.data2,
        });
      } else {
        console.log("Fetch ERROR", data);
      }
    });
  };

  handleSiteChange = (e) => {
    sessionStorage.removeItem("prev_group_id");
    sessionStorage.removeItem("prev_group_name");

    this.site_id = e.site_id;
    this.site_tz = e.site_tz;
    this.site_selected = true;

    this.setState({
      site_id: e.site_id,
    },()=> this.getTenantsInfoData());

    this.get_all_groups(e.value);

  };

  render() {
    const { site_id, tenant_info_data } = this.state;
    const defaultSortBy = [{ id: "Apartment", desc: false }]; //Default sort - use column names
    const hiddenColumns = ["report_bill_id"]; //No columns hidden in display

    return (
      <div>
        <Navigation />
        <div className="row">
          <div className="container react-select-report-runs ">
            <SiteSelect handleSiteChange={this.handleSiteChange} />
            {site_id && (
              <GroupSelect
                handleGroupSelect={(e) => this.getTenantsInfoData(e.value, e)}
                site_id={site_id}
              />
            )}

          </div>
        </div>
        <div>
          {tenant_info_data.length > 0 && (
              <TenantInfoTable
                  data={tenant_info_data}
                  defaultSortBy={defaultSortBy}
                  hiddenColumns={hiddenColumns}
              />
          )}
        </div>
      </div>
    );
  }
}
