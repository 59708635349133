/**
 * React Core Exports
 */
import React, {Component} from "react";

/**
 * Components That Complement Reader.js
 */
import Navigation from "../Navbar";
import AppFooter from "../AppFooter";
import "./report_runs.css";

/**
 * Internal Helpers
 */
import {Fetch} from "../../helpers/Fetch";
import SiteSelect from "../Global/Site_select2";

/**
 * Exported External Libraries
 */
import numeral from "numeral";
import {useTable, usePagination} from "react-table";
import {Button, Modal, OverlayTrigger, Table, Tooltip} from "react-bootstrap";

import dayjs from "dayjs";

//import timezone from "dayjs/plugin/timezone";
//import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import GroupSelect from "../Global/GroupSelect";
import SiteNotesTextArea from "../Global/SiteNotesTextArea";

//dayjs.extend(timezone);
//dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(LocalizedFormat);

/********************************************
 * END OF DAYJS IMPORTS
 ********************************************/
class Report_Runs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // show_edit_modal: false,
            // select_data_loaded: false,
            group_data: [],
            table_data: [],
            // rate_name: "",
            // tariff: "",
            // report_group_name: "",
            show_keepers: true, //default when page opens
            report_group_id: sessionStorage.getItem("prev_group_id"),
            show_report_runs_error_modal: false,
            site_id: sessionStorage.getItem("prev_site_id"),
            // site_selected: false,
            site_notes:sessionStorage.getItem("prev_site_notes")
        };

        //Get any previous selection
        this.site_id = sessionStorage.getItem("prev_site_id");
        this.site_tz = sessionStorage.getItem("prev_site_tz");

        this.group_selected = false;

        //If there was a previous site selected
        if (this.site_id) {
            this.site_selected = true;
            this.get_all_groups(this.site_id);
            this.fillReportTable()
        } else {
            this.site_selected = false;
        }
    }

    /******************************************************************************
     *    Page event scripts
     ******************************************************************************/

    handleSiteChange = (e) => {
        console.log("handleSiteChange e", e);
        sessionStorage.setItem("prev_site_id", e.site_id);
        sessionStorage.setItem("prev_site_tz", e.site_tz);
        sessionStorage.setItem("o_value", e.o_value);
        sessionStorage.setItem("prev_site_name", e.label);
        sessionStorage.removeItem("prev_group_id");
        sessionStorage.removeItem("prev_group_name");

        this.site_id = e.site_id;
        this.site_tz = e.site_tz;
        this.site_selected = true;
        this.setState({
            site_id: e.site_id
        })

        this.get_all_groups(e.site_id);
    };

    get_all_groups = (site_id) => {
        Fetch(`Report_Runs.php`, {
            action: "fill_groups_dropdown",
            site_id: site_id,
        })
            .then((data) => {
                if (data.data.length === 1) {
                    this.setState({
                        group_data: data.data,
                        report_group_id: data.data[0].report_group_id,
                        report_group_name: data.data[0].report_group_name,
                    }, () => this.fillReportTable());
                    this.group_selected = true;
                    this.handleReportSelect(data.data[0].report_group_id);
                } else {
                    this.group_selected = false;
                    this.setState({
                        group_data: data.data,
                    });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    fillReportTable = () => {
        const {show_keepers, report_group_id} = this.state;
        Fetch("Report_Runs.php", {
            action: "fill_table",
            report_group_id: report_group_id,
            show_keepers: show_keepers ? "true" : "false",
        }).then((data) => {
            if (data.status === "ok" && data.data.length > 0) {
                this.group_selected = true;
                this.setState({
                    table_data: data.data,
                    // loaded: true,
                    rate_name: data.data[0].rate_name,
                    tariff: data.data[0].tariff_name,
                });
            }
        });
    }

    handleReportSelect = (e, val) => {
        const {show_keepers, report_group_id} = this.state;
        if (val || e) {
            // console.log("handleReportSelect", e, val);
            sessionStorage.setItem("prev_group_id", val.value);
            sessionStorage.setItem("prev_group_name", val.label);
            sessionStorage.setItem("rgid", val.value);
            if (report_group_id === "") {
                this.setState({
                    report_group_id: e,
                });
            }

        }

        Fetch("Report_Runs.php", {
            action: "fill_table",
            report_group_id: e ? e : report_group_id,
            show_keepers: show_keepers ? "true" : "false",
        }).then((data) => {
            if (data.status === "ok" && data.data.length > 0) {
                this.group_selected = true;
                this.setState({
                    table_data: data.data,
                    // loaded: true,
                    rate_name: data.data[0].rate_name,
                    tariff: data.data[0].tariff_name,
                });
            }
        });
    };

    handleShowAllRuns = (_e) => {
        //Show all runs checkbox change
        const {show_keepers} = this.state;
        // console.log("show_keepers", this.state);
        this.setState(
            {
                show_keepers: !show_keepers, //toggle value
            },
            () => {
                // console.log("after toggle", this.state.show_keepers);
                this.handleReportSelect(undefined);
            }
        );
    };

    handleStatusKeep = (e, gr_id) => {
        let keep_value = JSON.parse(e.target.value);
        Fetch("Report_Runs.php", {
            action: "set_keep",
            group_run_id: gr_id,
            keep_value: !keep_value ? "true" : "false",
        }).then((data) => {
            if (data.status !== "ok") {
                this.setState({
                    show_report_runs_error_modal: true,
                });
            }
        });
    };

    handleCloseModal = () => {
        this.setState({
            show_report_runs_error_modal: false,
        });
    };

    handleRes = (e, gr_id, t_id) => {
        e.preventDefault();

        Fetch("Report_Runs.php", {
            action: "ExcelExport",
            gr_id: gr_id,
            t_id: t_id,
        }).then((data) => {
            console.log("handleRes", data);

            //Check for error on API side
            if (data.status !== "ok") {
                //Problem
                alert(data.reason);
                return; //Do nothing else.
            }

            //switch (t_id) {
            switch (t_id) {
                default: //Unidentified template
                    // console.log("Unidentified tariff with id: " + t_id);
                    alert("Unidentified tariff with id: " + t_id);
                    break;
                case 11:
                    //LADWP CC and Topanga - tariff_id 11
                    console.log("ExcelTemplate: LADWP CC and Topanga - tariff_id 11");

                    let tenant_rows = [];
                    let unassigned_rows = [];
                    data.data.forEach((row) => {
                        /*
                                                    You need to build an Array of Arrays in the correct order for each row.
                                                    Each row object needs to be placed in the right position based on the template

                                                    From DB for CC and TOP - Valley fair will be different
                                                    {
                                                        "device_id": 3498,
                                                        "report_name": "Suite 2820",
                                                        "tenant_name": "Westfield Specialty Leasing",
                                                        "cust_field1": "",
                                                        "total_kwh": "6",
                                                        "peak_kw": "0.412543977552559",
                                                        "h_peak_kw": "0.382605959632201",
                                                        "h_peak_kwh": "1.40456771582103",
                                                        "h_peak_kvarh": "0.199999999999818",
                                                        "l_peak_kw": "0.286624203814426",
                                                        "l_peak_kwh": "1.18669107305323",
                                                        "l_peak_kvarh": "0.223016037248271",
                                                        "b_peak_kw": "0.412543977552559",
                                                        "b_peak_kwh": "2.96900504971563",
                                                        "b_peak_kvarh": "0.537247801345075"
                                                    }
                                                     */

                        if (row.tenant_name !== "No tenant assigned") {
                            //Load assigned to tenants
                            tenant_rows.push([
                                row.device_id,
                                row.report_name,
                                row.tenant_name,
                                row.cust_field1, //Treat this field as text
                                parseFloat(row.total_kwh),
                                parseFloat(row.peak_kw),
                                parseFloat(row.h_peak_kw),
                                parseFloat(row.h_peak_kwh),
                                parseFloat(row.h_peak_kvarh),
                                parseFloat(row.l_peak_kw),
                                parseFloat(row.l_peak_kwh),
                                parseFloat(row.l_peak_kvarh),
                                parseFloat(row.b_peak_kw),
                                parseFloat(row.b_peak_kwh),
                                parseFloat(row.b_peak_kvarh),
                            ]);
                        } else {
                            //Loads with no tenant assigned
                            unassigned_rows.push([
                                row.device_id,
                                row.report_name,
                                row.tenant_name,
                                row.cust_field1, //Treat this field as text
                                parseFloat(row.total_kwh),
                                parseFloat(row.peak_kw),
                                parseFloat(row.h_peak_kw),
                                parseFloat(row.h_peak_kwh),
                                parseFloat(row.h_peak_kvarh),
                                parseFloat(row.l_peak_kw),
                                parseFloat(row.l_peak_kwh),
                                parseFloat(row.l_peak_kvarh),
                                parseFloat(row.b_peak_kw),
                                parseFloat(row.b_peak_kwh),
                                parseFloat(row.b_peak_kvarh),
                            ]);
                        }
                    });

                    //Build the object to pass to excelExport
                    const ExcelObj = {
                        template: data.ExcelTemplate, //Excel template file
                        output_filename:
                            dayjs().format("YYYY_MM_DD_HH:mm_") + "ReportRun.xlsx", //Default filename of file

                        Worksheets: [
                            {
                                //Worksheet 1
                                worksheet: "Tenants", //Worksheet in template
                                start_row: 3, //Starting at row 3
                                start_col_num: 1, //Starting at column A=1, B=2
                                rows: tenant_rows, //***** Data needs to be formatted first to an Array of Arrays
                            },
                            {
                                //Worksheet 2
                                worksheet: "Unassigned", //Worksheet in template
                                start_row: 3, //Starting at row 3
                                start_col_num: 1, //Starting at column A=1, B=2
                                rows: unassigned_rows, //***** Data needs to be formatted first to an Array of Arrays
                            },
                        ],
                    };

                    import(
                        /* webpackChunkName: "ExcelExportReport_runs" */ "../../helpers/ExcelExport"
                        ).then((m) => {
                        //console.log("loaded module", m);
                        m.excelExport(ExcelObj);
                    });
                    break;
                case 13:
                    //Valley Fair PGE and EXP
                    // console.log("Valley Fair PGE and EXP");
                    let tenant_rows2 = [];
                    let unassigned_rows2 = [];
                    data.data.forEach((row) => {
                        if (row.tenant_name !== "No tenant assigned") {
                            //Load assigned to tenants
                            tenant_rows2.push([
                                row.device_id,
                                row.report_name,
                                row.tenant_name,
                                row.total_kwh ? row.total_kwh : "",
                                row.peak_kw ? row.peak_kw : "",
                                row.h_peak_kw ? row.h_peak_kw : "",
                                row.h_peak_kwh ? row.h_peak_kwh : "",
                                row.h_peak_kvarh ? row.h_peak_kvarh : "",
                                row.l_peak_kw ? row.l_peak_kw : "",
                                row.l_peak_kwh ? row.l_peak_kwh : "",
                                row.l_peak_kvarh ? row.l_peak_kvarh : "",
                                row.b_peak_kw ? row.b_peak_kw : "",
                                row.b_peak_kwh ? row.b_peak_kwh : "",
                                row.b_peak_kvarh ? row.b_peak_kvarh : "",
                            ]);
                        } else {
                            //Loads with no tenant assigned
                            unassigned_rows2.push([
                                row.device_id,
                                row.report_name,
                                row.tenant_name,
                                row.total_kwh ? row.total_kwh : "",
                                row.peak_kw ? row.peak_kw : "",
                                row.h_peak_kw ? row.h_peak_kw : "",
                                row.h_peak_kwh ? row.h_peak_kwh : "",
                                row.h_peak_kvarh ? row.h_peak_kvarh : "",
                                row.l_peak_kw ? row.l_peak_kw : "",
                                row.l_peak_kwh ? row.l_peak_kwh : "",
                                row.l_peak_kvarh ? row.l_peak_kvarh : "",
                                row.b_peak_kw ? row.b_peak_kw : "",
                                row.b_peak_kwh ? row.b_peak_kwh : "",
                                row.b_peak_kvarh ? row.b_peak_kvarh : "",
                            ]);
                        }
                    });

                    //Build the object to pass to excelExport
                    const ExcelObj2 = {
                        template: data.ExcelTemplate, //Excel template file
                        output_filename:
                            dayjs().format("YYYY_MM_DD_HH:mm_") + "ReportRun.xlsx", //Default filename of file
                        Worksheets: [
                            {
                                //Worksheet 1
                                worksheet: "Tenants", //Worksheet in template
                                start_row: 3, //Starting at row 3
                                start_col_num: 1, //Starting at column A=1, B=2
                                rows: tenant_rows2, //***** Data needs to be formatted first to an Array of Arrays
                            },
                            {
                                //Worksheet 2
                                worksheet: "Unassigned", //Worksheet in template
                                start_row: 3, //Starting at row 3
                                start_col_num: 1, //Starting at column A=1, B=2
                                rows: unassigned_rows2, //***** Data needs to be formatted first to an Array of Arrays
                            },
                        ],
                    };

                    // console.log(ExcelObj2);

                    import(
                        /* webpackChunkName: "ExcelExportReport_runs2" */ "../../helpers/ExcelExport"
                        ).then((m) => {
                        //console.log("loaded module", m);
                        m.excelExport(ExcelObj2);
                    });
                    break;
                case 12:
                    //Valley Fair SVP
                    // console.log("Valley Fair SVP");
                    let tenant_rows3 = [];
                    let unassigned_rows3 = [];
                    data.data.forEach((row) => {
                        if (row.tenant_name !== "No tenant assigned") {
                            //Load assigned to tenants
                            tenant_rows3.push([
                                row.device_id,
                                row.report_name,
                                row.tenant_name,
                                row.total_kwh ? row.total_kwh : "",
                                row.peak_kw ? row.peak_kw : "",
                                row.h_peak_kw ? row.h_peak_kw : "",
                                row.h_peak_kwh ? row.h_peak_kwh : "",
                                row.h_peak_kvarh ? row.h_peak_kvarh : "",
                                row.l_peak_kw ? row.l_peak_kw : "",
                                row.l_peak_kwh ? row.l_peak_kwh : "",
                                row.l_peak_kvarh ? row.l_peak_kvarh : "",
                                row.b_peak_kw ? row.b_peak_kw : "",
                                row.b_peak_kwh ? row.b_peak_kwh : "",
                                row.b_peak_kvarh ? row.b_peak_kvarh : "",
                            ]);
                        } else {
                            //Loads with no tenant assigned
                            unassigned_rows3.push([
                                row.device_id,
                                row.report_name,
                                row.tenant_name,
                                row.total_kwh ? row.total_kwh : "",
                                row.peak_kw ? row.peak_kw : "",
                                row.h_peak_kw ? row.h_peak_kw : "",
                                row.h_peak_kwh ? row.h_peak_kwh : "",
                                row.h_peak_kvarh ? row.h_peak_kvarh : "",
                                row.l_peak_kw ? row.l_peak_kw : "",
                                row.l_peak_kwh ? row.l_peak_kwh : "",
                                row.l_peak_kvarh ? row.l_peak_kvarh : "",
                                row.b_peak_kw ? row.b_peak_kw : "",
                                row.b_peak_kwh ? row.b_peak_kwh : "",
                                row.b_peak_kvarh ? row.b_peak_kvarh : "",
                            ]);
                        }
                    });

                    //Build the object to pass to excelExport
                    const ExcelObj3 = {
                        template: data.ExcelTemplate, //Excel template file
                        output_filename:
                            dayjs().format("YYYY_MM_DD_HH:mm_") + "ReportRun.xlsx", //Default filename of file
                        Worksheets: [
                            {
                                //Worksheet 1
                                worksheet: "Tenants", //Worksheet in template
                                start_row: 3, //Starting at row 3
                                start_col_num: 1, //Starting at column A=1, B=2
                                rows: tenant_rows3, //***** Data needs to be formatted first to an Array of Arrays
                            },
                            {
                                //Worksheet 2
                                worksheet: "Unassigned", //Worksheet in template
                                start_row: 3, //Starting at row 3
                                start_col_num: 1, //Starting at column A=1, B=2
                                rows: unassigned_rows3, //***** Data needs to be formatted first to an Array of Arrays
                            },
                        ],
                    };
                    // console.log(ExcelObj2)

                    import(
                        /* webpackChunkName: "ExcelExportReport_runs3" */ "../../helpers/ExcelExport"
                        ).then((m) => {
                        //console.log("loaded module", m);
                        m.excelExport(ExcelObj3);
                    });
                    break;
            }
        });
    };

    render() {
        const {
            group_data,
            table_data,
            rate_name,
            tariff,
            // report_group_name,
            show_keepers,
            show_report_runs_error_modal,
            // report_group_id,
            site_id,
            site_notes
        } = this.state;

        const handleSiteNotesChange = (value)=>{
            this.setState({
                site_notes: value
            });
        }

        //Map the report group selections
        const group_data_options =
            group_data &&
            group_data.map((val) => ({
                label: val.report_group_name,
                value: val.report_group_id,
            }));

        //Sort the options alphabetically
        if (group_data)
            group_data_options.sort((a, b) => a.label.localeCompare(b.label));

        console.log(group_data.length);

        const columns = [
            {
                Header: () => (
                    <div className="me-5" style={{textAlign: "center"}}>
                        Keep
                    </div>
                ),
                accessor: "status_keep",
                key: "status_keep",
                Cell: (props) => (
                    <div className="text-center me-5 ">
                        <input
                            type="checkbox"
                            value={props.value}
                            defaultChecked={props.value}
                            onChange={(e) =>
                                this.handleStatusKeep(e, props.row.original.group_run_id)
                            }
                            className="me-2 mx-2"
                        />
                        {props.value && (
                            <button
                                className="btn-primary btn-sm"
                                onClick={(e) =>
                                    this.handleRes(
                                        e,
                                        props.row.original.group_run_id,
                                        props.row.original.tariff_id
                                    )
                                }
                            >
                                RES
                            </button>
                        )}
                    </div>
                ),
            },
            {
                Header: () => (
                    <div className="me-5" style={{textAlign: "center"}}>
                        #
                    </div>
                ),
                accessor: "group_run_id",
                key: "group_run_id",
                Cell: (props) => <div className="text-center me-5 ">{props.value}</div>,
            },
            {
                Header: () => (
                    <div className="me-5" style={{textAlign: "center"}}>
                        Report from
                    </div>
                ),
                accessor: "report_from_date",
                key: "report_from_date",
                Cell: (props) => (
                    <div className="text-center me-5 ">
                        {dayjs.utc(props.value).tz(this.site_tz).format("MM-DD-YYYY HH:mm")}
                    </div>
                ),
            },
            {
                Header: () => (
                    <div className="me-5" style={{textAlign: "center"}}>
                        Report to
                    </div>
                ),
                accessor: "report_to_date",
                key: "report_to_date",
                Cell: (props) => (
                    <div className="text-center me-5 ">
                        {dayjs.utc(props.value).tz(this.site_tz).format("MM-DD-YYYY HH:mm")}
                    </div>
                ),
            },
            {
                Header: () => (
                    <div className="mx-5" style={{textAlign: "center"}}>
                        Total
                    </div>
                ),
                accessor: "run_total",
                key: "run_total",
                Cell: (props) => (
                    <div className="text-end me-5 ">
                        ${numeral(props.value).format("0,0.00")}
                    </div>
                ),
            },
            {
                accessor: "run_start_date",
            },
            {
                accessor: "requested_by",
            },
            {
                accessor: "rate_name",
            },
            {
                accessor: "tariff_name",
            },
        ];

        function TableRender({columns, data, site_tz}) {
            const {
                getTableProps,
                getTableBodyProps,
                headerGroups,
                page,

                canPreviousPage,
                canNextPage,
                pageOptions,
                pageCount,
                gotoPage,
                nextPage,
                previousPage,
                setPageSize,
                prepareRow,
                state: {pageIndex, pageSize},
            } = useTable(
                {
                    columns,
                    data,
                    initialState: {
                        pageIndex: 0,
                        hiddenColumns: [
                            "run_start_date",
                            "requested_by",
                            "rate_name",
                            "tariff_name",
                        ],
                    },
                },
                usePagination
            );

            /*
                        Sort by from date (most recent at top) and then by group_run_id (most recent at top)
                         */

            page.sort(
                (a, b) =>
                    Date.parse(b.values.report_from_date) -
                    Date.parse(a.values.report_from_date) ||
                    b.values.group_run_id - a.values.group_run_id
            );

            return (
                <>
                    <Table //Bootstrap table settings
                        bordered
                        hover
                        size="sm"
                        {...getTableProps()}
                    >
                        <thead>
                        {headerGroups.map((headerGroup, index) => (
                            <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, z) => (
                                    <th key={z} {...column.getHeaderProps()}>
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);

                            return (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            <div>
                                                Report Run Date:{" "}
                                                {dayjs
                                                    .utc(row.values.run_start_date)
                                                    .tz(site_tz)
                                                    .format("MM-DD-YYYY HH:mm")}
                                            </div>
                                            <div>
                                                {row.values.requested_by &&
                                                    "Requested By:" + row.values.requested_by}
                                            </div>
                                            <div>Rate Name: {row.values.rate_name}</div>
                                            <div>Tariff Name: {row.values.tariff_name}</div>
                                        </Tooltip>
                                    }
                                    color="black"
                                >
                                    <tr key={i} {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </OverlayTrigger>
                            );
                        })}
                        </tbody>
                    </Table>
                    <div className="pagination">
                        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            {"<<"}
                        </button>
                        {" "}
                        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                            {"<"}
                        </button>
                        {" "}
                        <button onClick={() => nextPage()} disabled={!canNextPage}>
                            {">"}
                        </button>
                        {" "}
                        <button
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                        >
                            {">>"}
                        </button>
                        {" "}
                        <span>
              Page{" "}
                            <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
                        <span>
              | Go to page:{" "}
                            <input
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={(e) => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                    gotoPage(page);
                                }}
                                style={{width: "100px"}}
                            />
            </span>{" "}
                        <select
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                            }}
                        >
                            {[10, 20, 30, 40, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </div>
                </>
            );
        }

        return (
            <div>
                <Navigation/>

                <Modal
                    show={show_report_runs_error_modal}
                    onHide={this.handleCloseModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Repeated Entry Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Another run is already checked. You must uncheck the other before
                        selecting this one
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.handleCloseModal}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>

                <div className="container-fluid main-container-div">
                    <>
                        <div className="container react-select-report-runs ">
                            <SiteSelect handleSiteChange={this.handleSiteChange} site_notes={site_notes}/>
                            {site_id &&
                                <GroupSelect handleGroupSelect={(e) => this.handleReportSelect(e.value, e)}
                                             site_id={site_id}/>
                            }
                            <SiteNotesTextArea site_id={site_id} handleSiteNotesChange={handleSiteNotesChange}/>

                        </div>
                        <div className="text-center">
                            <h3>
                                <b>Rate</b>: {rate_name}
                            </h3>
                            <h3>
                                <b>Tariff:</b> {tariff}
                            </h3>
                            <h3>
                                <b>Time zone:</b>
                                {this.site_tz}
                            </h3>
                        </div>
                        <div align="center" className="mb-2">
                            <input
                                type="checkbox"
                                name="show_keepers"
                                value={show_keepers ? "false" : "true"}
                                checked={show_keepers === false}
                                onChange={this.handleShowAllRuns}
                            />
                            <label className="mx-1">Show all runs</label>
                        </div>
                    </>
                    {table_data.length > 0 && (

                        <div className=" container align-items-center justify-content-center">
                            <TableRender
                                columns={columns}
                                data={table_data}
                                site_tz={this.site_tz}
                            />
                        </div>
                    )}

                </div>
                {/* Standard application footer for all pages */}
                <AppFooter/>
            </div>
        );
    }
}

export default Report_Runs;
